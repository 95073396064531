<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="">
            <h4 class="title">Detail Bonus</h4>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/data-payroll">Payroll </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Detail
                </li>
              </ol>
            </nav>
          </div>
          <!-- <div>
            <button class="btn btn-save mr-3">Simpan</button>
            <button class="btn btn-blue">Simpan & Sync</button>
          </div> -->
        </div>

        <div class="card-table info-detail">
          <div class="row mt-3">
            <div class="col-6">
              <div class="title">Detail Bonus</div>
              <div class="sub-title">Detail informasi bonus karyawan</div>
            </div>
            <div class="col-6 text-right">
              <div
                class="btn btn-red mr-2"
                style="cursor: pointer"
                v-if="
                  formData.status == 'aktif' &&
                  formData.status_pembayaran == 'belum' &&
                  (checkAccess('payroll') ||
                    checkAccess('payroll', 'cru_payroll'))
                "
                @click="setCancel"
              >
                <span class="fas fa-spinner fa-spin" v-if="onPayment"></span>
                Batalkan yang belum dibayar
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-6 col-sm-4">
              <div class="d-flex mb-2">
                <div class="text">Payroll</div>
                <div class="value text-uppercase">{{ formData.kategori }}</div>
              </div>
              <div class="d-flex mb-2">
                <div class="text">Bulan</div>
                <div class="value">{{ formatMonth(formData.bulan) }}</div>
              </div>
              <div class="d-flex mb-2">
                <div class="text">Tipe Bayar</div>
                <div class="value text-capitalize">
                  {{ formData.tipe_bayar }}
                </div>
              </div>
            </div>

            <div class="col-6 col-sm-4">
              <div class="d-flex mb-2">
                <div class="text">Departemen</div>
                <div class="value">
                  {{ formData.nama_divisi ? formData.nama_divisi : "-" }}
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="text">Tanggal</div>
                <div class="value">
                  {{ formatDate(formData.tanggal_bayar) }}
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="text">Akun Kas & Bank</div>
                <div class="value">{{ formData.akun_perkiraan }}</div>
              </div>
            </div>

            <div class="col-12 col-sm-4">
              <div class="d-flex mb-2 align-items-center">
                <div class="text">Status</div>
                <div class="value">
                  <div
                    class="red-bedge text-capitalize"
                    v-if="formData.status_pembayaran != 'lunas'"
                  >
                    {{
                      formData.status != "aktif"
                        ? formData.status
                        : formData.status_pembayaran
                    }}
                  </div>
                  <div class="green-bedge" v-else>Lunas</div>
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="text">Keterangan</div>
                <div class="value">
                  {{ formData.keterangan ? formData.keterangan : "-" }}
                </div>
              </div>
            </div>
          </div>

          <div class="card mt-5">
            <div class="row">
              <div class="col-6">
                <div class="title">Daftar Karyawan</div>
                <div class="sub-title">Detail informasi payroll karyawan</div>
              </div>
              <div class="col-6 d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  @click="downloadData()"
                  class="btn btn-blue"
                  :disabled="onDownload"
                  v-if="listDetail.length > 0"
                >
                  <img
                    src="/img/icon-export.svg"
                    alt=""
                    class="mr-2"
                    style="width: 15px"
                    v-if="!onDownload"
                  />
                  <span class="fas fa-spin fa-spinner" v-else></span>Export
                </button>
                <div
                  class="btn btn-add ml-2"
                  style="cursor: pointer"
                  @click="setPayment"
                  v-if="
                    formData.status == 'aktif' &&
                    formData.status_pembayaran == 'belum' &&
                    (checkAccess('payroll') ||
                      checkAccess('payroll', 'cru_payroll'))
                  "
                  :disabled="onPayment"
                >
                  <span class="fas fa-spinner fa-spin" v-if="onPayment"></span>
                  Sudah Dibayarkan
                </div>
              </div>
            </div>
            <hr />
            <div class="table-responsive">
              <table
                class="table table-bordered table-striped table-hover"
                id="tablePayrollBonusDetail"
              >
                <thead>
                  <tr>
                    <th style="width: 5%" class="text-center">
                      <label style="margin-bottom: 0px">
                        <input
                          :disabled="formData.status_pembayaran == 'lunas'"
                          v-model="selectAll"
                          :value="true"
                          @change="changeSelect"
                          type="checkbox"
                          name="checkbox"
                        />
                      </label>
                    </th>
                    <th style="width: 10%">ID Karyawan</th>
                    <th style="width: 20%">Nama Karyawan</th>
                    <th style="width: 10%">Departemen</th>
                    <th style="width: 10%">Jabatan</th>
                    <th style="width: 10%">Total Bonus</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(value, index) in listDetail"
                    :key="index"
                    :data-toggle="
                      value.status_pembayaran == 'lunas' ||
                      this.formData.status == 'aktif'
                        ? 'tooltip'
                        : ''
                    "
                    data-placement="top"
                    :style="{
                      cursor:
                        value.status_pembayaran == 'lunas' ||
                        this.formData.status == 'aktif'
                          ? 'pointer'
                          : 'no-drop',
                      textDecoration:
                        value.status_pembayaran == 'lunas' ||
                        this.formData.status == 'aktif'
                          ? 'none'
                          : 'line-through',
                      color:
                        value.status_pembayaran == 'lunas' ||
                        this.formData.status == 'aktif'
                          ? '#000'
                          : 'red',
                    }"
                    :title="
                      value.status_pembayaran == 'lunas' ||
                      this.formData.status == 'aktif'
                        ? 'Klik untuk melihat detail'
                        : ''
                    "
                  >
                    <td class="text-center">
                      <label class="" style="margin-bottom: 0px">
                        <input
                          type="checkbox"
                          v-if="value.status_pembayaran != 'lunas'"
                          v-model="PayrollPaymentList[value.id_payroll_detail]"
                          :value="true"
                          name="checkbox"
                        />
                        <input
                          :disabled="true"
                          type="checkbox"
                          v-else
                          checked="true"
                          :value="true"
                          name="checkbox"
                        />
                      </label>
                    </td>
                    <td
                      v-on:click="
                        detail(value.id_payroll_detail, value.status_pembayaran)
                      "
                    >
                      {{ value.no_karyawan }}
                    </td>
                    <td
                      v-on:click="
                        detail(value.id_payroll_detail, value.status_pembayaran)
                      "
                    >
                      {{ value.nama_karyawan }}
                    </td>
                    <td
                      v-on:click="
                        detail(value.id_payroll_detail, value.status_pembayaran)
                      "
                    >
                      {{ value.nama_divisi }}
                    </td>
                    <td
                      v-on:click="
                        detail(value.id_payroll_detail, value.status_pembayaran)
                      "
                    >
                      {{ value.nama_jabatan }}
                    </td>
                    <td
                      v-on:click="
                        detail(value.id_payroll_detail, value.status_pembayaran)
                      "
                    >
                      <div class="d-flex justify-content-between">
                        <div>Rp</div>
                        <div>{{ formatMoney(value.nilai_pemasukan) }}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
// import Select2 from "vue3-select2-component";
// import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

import {
  get_PayrollDetail,
  get_PayrollExport,
  post_PayrollCancel,
  post_PayrollPayment,
} from "../../../../actions/payroll";
import { showAlert, checkModuleAccess } from "../../../../helper";
import moment from "moment";
import "moment/locale/id";
import { DATA } from '../../../../constans/Url';

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    // Select2,
    // Datepicker,
    // TableLite
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      listDetail: [],
      DataTable: "",
      selectAll: false,
      onPayment: false,
      onDownload: false,
      PayrollPaymentList: [],
      formData: {
        kategori: "Gaji",
        bulan: "",
        tipe_bayar: "",
        tanggal_bayar: "",
        nama_divisi: "",
        keterangan: "",
        akun_perkiraan: "",
        status_pembayaran: "",
        status: "",
      },
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  mounted() {
    this.tooltip();
  },

  created() {
    this.getData();
  },

  beforeUnmount() {
    if (this.DataTable) {
      this.DataTable.destroy();
      this.DataTable = "";
    }
  },

  methods: {
    downloadData() {
      this.onDownload = true;
      var dataForm = {...this.formData}
      dataForm.kategori = this.formData.kategori.toString().toUpperCase();
      dataForm.bulan = this.formatMonth(this.formData.bulan);
      dataForm.nama_divisi = this.formData.nama_divisi ? this.formData.nama_divisi : "-"
      dataForm.tanggal_bayar = this.formatDate(this.formData.tanggal_bayar)
      dataForm.keterangan = this.formData.keterangan ? this.formData.keterangan : "-"
      get_PayrollExport(
        {
          type: "bonus",
          list: this.listDetail,
          data: dataForm,
        },
        (res) => {
          var fileURL = DATA.COMPANY(res.file);
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          var nameFile = res.file;
          fileLink.setAttribute("download", nameFile);
          document.body.appendChild(fileLink);
          fileLink.click();
          setTimeout(() => {
            this.onDownload = false;
          }, 1000);
        },
        () => {
          this.onDownload = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },
    detail(id, status) {
      if (status == "lunas" || this.formData.status == "aktif") {
        $('[data-toggle="tooltip"]').tooltip("hide");
        this.$router.push({
          name: "BonusSlip",
          params: {
            id: id,
          },
        });
      }
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    changeSelect() {
      var isAll = false;
      if (this.selectAll) {
        isAll = true;
      }
      for (const key in this.listDetail) {
        if (Object.hasOwnProperty.call(this.listDetail, key)) {
          const element = this.listDetail[key];
          this.PayrollPaymentList[element.id_payroll_detail] = isAll;
        }
      }
    },

    formatMonth(string) {
      return moment(string + "-01").format("MMMM YYYY");
    },

    formatDate(string) {
      return moment(string).format("DD MMMM YYYY");
    },

    getData() {
      get_PayrollDetail(
        this.$route.params.id,
        (res) => {
          var { detail, data } = res;
          this.formData.kategori = data.kategori;
          this.formData.bulan = data.bulan;
          this.formData.tanggal_bayar = data.tanggal_bayar;
          this.formData.keterangan = data.keterangan;
          this.formData.status = data.status;
          this.formData.nama_divisi = data.nama_divisi;
          this.formData.status_pembayaran = data.status_pembayaran;
          this.formData.tipe_bayar = data.tipe_bayar;
          this.formData.akun_perkiraan = data.nama_akun;
          this.listDetail = detail;
          if (this.DataTable) {
            this.DataTable.destroy();
          }
          for (const key in detail) {
            if (Object.hasOwnProperty.call(detail, key)) {
              const element = detail[key];
              if (detail.status_pembayaran != "lunas") {
                this.PayrollPaymentList[element.id_payroll_detail] = false;
              }
            }
          }
          setTimeout(() => {
            this.DataTable = $("#tablePayrollBonusDetail").DataTable({
              info: false,
            });
            this.tooltip();
          }, 500);
        },
        () => {
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Data payroll tidak ditemukan",
          });
        }
      );
    },

    postPayment() {
      var id_detail = [];
      for (const key in this.PayrollPaymentList) {
        if (Object.hasOwnProperty.call(this.PayrollPaymentList, key)) {
          const element = this.PayrollPaymentList[key];
          if (element) {
            id_detail.push(key);
            // break;
          }
        }
      }
      this.onPayment = true;
      var data = {
        id_payroll: this.$route.params.id,
        id_payroll_detail: id_detail,
      };
      post_PayrollPayment(
        data,
        (res) => {
          this.onPayment = false;
          var { detail, data } = res.payroll;
          this.formData.status_pembayaran = data.status_pembayaran;
          this.listDetail = detail;
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: "Data pembayaran berhasil dicatat",
          });
          if (this.DataTable) {
            this.DataTable.destroy();
          }
          setTimeout(() => {
            this.DataTable = $("#tablePayrollItem").DataTable({
              info: false,
            });
            this.tooltip();
          }, 500);
        },
        () => {
          this.onPayment = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showConfirmButton: false,
            showCancelButton: true,
          });
        }
      );
    },

    setPayment() {
      var id_detail = [];
      for (const key in this.PayrollPaymentList) {
        if (Object.hasOwnProperty.call(this.PayrollPaymentList, key)) {
          const element = this.PayrollPaymentList[key];
          if (element) {
            id_detail.push(key);
            // break;
          }
        }
      }
      if (id_detail.length > 0) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, perubahan akan tersimpan dan tidak bisa di edit kembali?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postPayment();
          },
        });
      } else {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Pilih karyawan terlebih dahulu untuk melanjutkan proses pembayaran",
          showConfirmButton: false,
        });
      }
    },
  },

  postCancel() {
    this.onPayment = true;
    var data = {
      id_payroll: this.$route.params.id,
    };
    post_PayrollCancel(
      data,
      () => {
        this.onPayment = false;
        // var { detail, data } = res.payroll;
        this.getData();
        // this.formData.status_pembayaran = data.status_pembayaran;
        // this.formData.status = data.status;
        // this.listDetail = detail;
        // showAlert(this.$swal, {
        //   title: "BERHASIL!",
        //   msg: "Data payroll berhasil dibatalkan",
        // });
        // if (this.DataTable) {
        //   this.DataTable.des troy();
        // }
        // setTimeout(() => {
        //   this.DataTable = $("#tablePayrollItem").DataTable({
        //     info: false,
        //   });
        //   this.tooltip();
        // }, 500);
      },
      () => {
        this.onPayment = false;
        showAlert(this.$swal, {
          title: "GAGAL!",
          msg: "Terjadi kesalahan, silakan ulangi kembali",
          showConfirmButton: false,
          showCancelButton: true,
        });
      }
    );
  },

  setCancel() {
    showAlert(this.$swal, {
      title: "PERHATIAN!",
      msg: "Apakah anda yakin akan membatalkan payroll yang belum dibayarkan, tindakan ini tidak bisa di edit kembali?",
      showCancelButton: true,
      cancelButtonText: "Batal",
      confirmButtonText: "Ya, Lanjutkan",
      onSubmit: () => {
        this.postCancel();
      },
    });
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}
.card {
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}
.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

.text {
  font-weight: 500;
  font-size: 16px;
  width: 197px;
}

.vavlue {
  font-size: 16px;
}
input[type="checkbox"].checkbox:disabled {
  background-color: #68caf8 !important;
}
</style>
