import { API } from "../../constans/Url";
import { apiClient } from "../../helper";

const get_PayrollList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PAYROLL.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PayrollPraSave = (params = {
  tipe: '',
  id_company: '',
}, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PAYROLL.PRA_SAVE, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PayrollDetail = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PAYROLL.GET, {id: id}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PayrollSlip = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PAYROLL.GET_SLIP, {id: id}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PayrollPreviousItem = (params = {
  tipe: '',
  id_karyawan: '',
}, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PAYROLL.PREVIOUS_ITEM, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_PayrollSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PAYROLL.SAVE, params).then(res => {
    if (res.status === 200 || res.status === 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_PayrollPayment = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PAYROLL.PAYMENT, params).then(res => {
    if (res.status === 200 || res.status === 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PayrollProjectList = (params = {
  id_company: '',
}, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PAYROLL.PROJECT, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PayrollProjectManPower = (params = {
  id_company: '',
  kode_project: '',
}, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PAYROLL.PROJECT_MANPOWER, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_PayrollCancel = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PAYROLL.CANCEL, params).then(res => {
    if (res.status === 200 || res.status === 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PayrollExport = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PAYROLL.EXPORT, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


export {
  get_PayrollDetail, get_PayrollExport, get_PayrollList, get_PayrollPraSave, get_PayrollPreviousItem, get_PayrollProjectList,
  get_PayrollProjectManPower, get_PayrollSlip, post_PayrollCancel, post_PayrollPayment, post_PayrollSave
};
